<script lang="ts" setup>
import type { SearchTypeEnum } from '#gql/default'

const { t } = useI18n()
const selectedTypes = defineModel({ default: [], type: Array })

function toggleType(type: SearchTypeEnum) {
  const index = selectedTypes.value.indexOf(type)
  if (index === -1) {
    selectedTypes.value.push(type)
  } else {
    selectedTypes.value.splice(index, 1)
  }
}

defineOptions({
  name: 'SearchAutosuggestFilters',
})
</script>

<template>
  <div class="contents">
    <Button
      :size="BUTTON_SIZE.SM"
      :theme="
        selectedTypes.includes(SEARCH_TYPE_ENUM.MOVIE)
          ? BUTTON_THEME.PRIMARY
          : BUTTON_THEME.SECONDARY
      "
      icon="kh:movie-roll"
      :text="t('movie')"
      @click="toggleType(SEARCH_TYPE_ENUM.MOVIE as SearchTypeEnum)"
    />
    <Button
      :size="BUTTON_SIZE.SM"
      :theme="
        selectedTypes.includes(SEARCH_TYPE_ENUM.CINEMA)
          ? BUTTON_THEME.PRIMARY
          : BUTTON_THEME.SECONDARY
      "
      icon="kh:theater-outline"
      :text="t('cinema')"
      @click="toggleType(SEARCH_TYPE_ENUM.CINEMA as SearchTypeEnum)"
    />
    <Button
      :size="BUTTON_SIZE.SM"
      :theme="
        selectedTypes.includes(SEARCH_TYPE_ENUM.CITY)
          ? BUTTON_THEME.PRIMARY
          : BUTTON_THEME.SECONDARY
      "
      icon="kh:map-marker-outline"
      :text="t('city')"
      @click="toggleType(SEARCH_TYPE_ENUM.CITY as SearchTypeEnum)"
    />
  </div>
</template>

<i18n>
de:
  movie: "Film"
  cinema: "Kino"
  city: "Stadt"
  person: "Person"
es:
  movie: "Película"
  cinema: "Cine"
  city: "Ciudad"
  person: "Persona"
</i18n>
